<template>
    <Toast />

    <Dialog v-model:visible="profileModal" :modal="true" :header="this.$store.state.id" class="p-fluid"
        @onCancel="profileModal = false">
        <Card style="width: 20rem">
            <template #header>
                <img alt="user header" src="./images/peachbite.png" />
            </template>
            <template #title>
                <div style="text-align: center;">(C) CARETAIL KOREA</div>
            </template>
            <template #subtitle>Copyright © CARETAIL KOREA CO., LTD. All Rights Reserved. </template>
            <template #content>
            </template>
            <template #footer>
                <div style="margin: 0 auto; text-align: center;display:flex;flex-direction:row; " class="justify-content-center">
                    <Button icon="pi pi-check" severity="info" class="justify-content-center;" style="width:48%; height: 2rem; font-size: 0.7rem;"
                        label="Change password" 
                        @click.prevent="doChangePasswd($event, 'modal')" />
                    <Button icon="pi pi-times" label="Logout" severity="danger" class="justify-content-center"
                        style="height: 2rem; width:48%; font-size: 0.7rem; margin-left:2px;"
                        @click.prevent="doLogout" />
                </div>
            </template>
        </Card>
    </Dialog>

    <Dialog v-model:visible="changePasswdFlag" :modal="true" :header="this.$store.state.id" class="p-fluid"
        @onCancel="changePasswdFlag = false">
        <div class="field">
            <div class="p-float-label">
                <Password v-model="passwd.user_current" maxlength="200" />
                <label for="memberPassword">Current</label>
            </div>
        </div>
        <div class="field">
            <div class="p-float-label">
                <Password v-model="passwd.user_new" maxlength="200" />
                <label for="memberPassword">Change</label>
            </div>
        </div>
        <div class="field">
            <div class="p-float-label">
                <Password v-model="passwd.user_comp" maxlength="200" />
                <label for="memberPassword">verify password</label>
            </div>
        </div>
        <div class="field">
            <div class="p-inputgroup" style="justify-content: center;">
                <Button type="submit" label="Change" class="mt-2" style="font-size:0.6rem;width:30%;" @click.prevent="doChangePasswd($event, 'save')"/>
                <Button type="submit" label="Cancel" class="mt-2 p-button-danger" style="font-size:0.6rem;width:31%;margin-left:2%;" @click.prevent="changePasswdFlag = false"/>
            </div>
            <div class="p-inputgroup" style="justify-content: center;margin-top: 2rem;">
                <Button type="submit" label="Initialize" class="mt-2 p-button-warning" style="justify-content:center;font-size:0.6rem;width:35%;margin-left:2%;" @click.prevent="doChangePasswd($event, 'init')"/>
            </div>
        </div> 
    </Dialog>

    <div class="main">
        <div style="border:0px; background-color:darkblue; width:100%; height:2.5rem; color: white; display:flex; flex-direction:row;align-items: center;">
            <div style="width: 85%; text-align: left; margin-left: 1%; float:auto;">
                <div class="p-inputgroup">
                    <div style="width:20%;border:0px; padding-left:0.4rem; text-align: left;font-weight: bold; display:flex; flex-direction:row;">
                        <div style="margin-top: 0.25rem;">
                            <a href="gohome"><img src="./images/peachbite_logo.png" style="height: 1.5rem;" /></a>
                        </div>
                        <div style="margin-top: 0.1rem; margin-left: 1rem;">
                            <Button v-if="getlogin" label="Caretail Menu" icon="pi pi-fw pi-bars" class="p-button-error"
                                style="width:20%; position:relative; font-size:1rem; width:15rem; height: 1.7rem; vertical-align:middle;" 
                                @click.prevent="toggle" />
                        </div>
                        <!-- <div style="margin-top: 0.25rem; font-size:1rem;">
                            {{ "Caretail Manager V1" }}
                        </div> -->
                    </div>
                    <div style="width:90%; align-items: center;text-align: center;justify-content: center; padding-top: 0.25rem; font-size:1rem;">
                        {{ this.$router.currentRoute.value.meta.title }}
                    </div>
                </div>
            </div>
            <div style="width:15%; text-align: right; margin-left: 1%; margin-top: 0.2rem;">

                <div class="card flex justify-content-center flex-wrap gap-3"  >
                    <span style="font-size:0.7rem;margin-top: 7px;">{{this.$store.state.role}}</span>
                    <Button v-if="getlogin" :label="this.$store.state.Id" icon="pi pi-fw pi-sign-out"
                        severity="danger" rounded style="width:10rem;margin-left:2px;font-size:1rem; height: 1.7rem;"  @click.prevent='doProfile'/>
                    <Button v-else label="Login" icon="pi pi-fw pi-user"
                        severity="info" rounded style="width:10rem; margin-right: 2px; font-size:1rem; width:10rem; height: 1.7rem;" />
                </div>
            </div>
        </div>
        <div style="border:0px;margin-left:0px; background-color:rgb(30,144,255);">
            <sideMenu :menuVisible="menuVisible" :userMenuItems="AdminMenuItems" :menuTitle="selectedMenuItem" @selected_menu="recvMenuItem"/>
        </div>
        <div style="height:90%; margin-top: 2px;">
            <router-view />
        </div>
    </div>
</template>

<script>
import sideMenu from './views/sideMenu.vue';

export default {
    components: { sideMenu,  },
    data: function () {
        return {
            httpConfig : 
            {
                headers: 
                {
                    Authorization: `${this.$cookies.get(
                        "token_type"
                    )} ${this.$cookies.get("accessToken")}`,
                    Accept: "application/json",
                    'Content-Type': 'multipart/form-data',
                },
            },
            routeItem: { path: '', name: '', component: '', meta: { title: '', role: '', status: null, authRequired: false, icon: null }, props: true, },
            profileModal: false,
            mountedIndex: 0,
            changePasswdFlag: false,
            passwd:{user_id:'', user_current:'', user_new:'', user_comp:''},
            tokenSize: 0,
            menuVisible: false,
            menuEnabled: false,
            userMenuItems:[],
            AdminMenuItems:
            [
                {   label: process.env.VUE_APP_MENU_STANDMGR,
                    icon: 'pi pi-fw pi-list',
                    items: 
                    [
                        {label: process.env.VUE_APP_VAR_ORGMGR, title: 'org', icon: 'pi pi-fw pi-building', command: () => {this.$router.push('org'); }},
                        // {label: process.env.VUE_APP_VAR_GRADEMGR, title: 'grade', icon: 'pi pi-fw pi-id-card', command: () => {this.$router.push('grade'); }},
                        // {label: process.env.VUE_APP_VAR_AGREEDMGR, title: 'agreed', icon: 'pi pi-fw pi-verified', command: () => {this.$router.push('agreed'); }},
                        {label: process.env.VUE_APP_VAR_DEVCONMGR, title: 'device', icon: 'pi pi-fw pi-wifi', command: () => {this.$router.push('device'); }},
                        {label: process.env.VUE_APP_VAR_FRMMGR, title: 'firm', icon: 'pi pi-fw pi-info-circle', command: () => {this.$router.push('firm'); }},
                        {label: process.env.VUE_APP_VAR_DOMAINMGR, title: 'dns', icon: 'pi pi-fw pi-sitemap', command: () => {this.$router.push('dns'); }},
                        {label: process.env.VUE_APP_VAR_PETMGR, title: 'pet', icon: 'pi pi-fw pi-discord', command: () => {this.$router.push('pet'); }},
                        {label: process.env.VUE_APP_VAR_PETSTD, title: 'petstd', icon: 'pi pi-fw pi-discord', command: () => {this.$router.push('petstd'); }},
                        {label: process.env.VUE_APP_VAR_QNAMGR, title: 'qna', icon: 'pi pi-fw pi-whatsapp', command: () => {this.$router.push('qna'); }},
                        {label: process.env.VUE_APP_VAR_LOGHIST, title: 'log', icon: 'pi pi-fw pi-sign-in', command: () => {this.$router.push('log'); }},
                        // {label: process.env.VUE_APP_VAR_GWMGR, title: 'gw', icon: 'pi pi-fw pi-tablet', command: () => {this.$router.push('gw'); }},
                        {label: process.env.VUE_APP_VAR_WATCHMGR, title: 'watch', icon: 'pi pi-fw pi-tablet', command: () => {this.$router.push('watch'); }},
                        {label: process.env.VUE_APP_VAR_RGNMGR, title: 'rgn', icon: 'pi pi-fw pi-info-circle', command: () => {this.$router.push('rgn'); }},
                        // {label: process.env.VUE_APP_VAR_SERVICEMGR, title: 'service', icon: 'pi pi-fw pi-tags', command: () => {this.$router.push('service'); }},
                        // {label: process.env.VUE_APP_VAR_COMMMGR, title: 'com', icon: 'pi pi-fw pi-cog', command: () => {this.$router.push('com'); }},
                                                
                        // {label: 'MqttWS', title: 'mqttws', icon: 'pi pi-fw pi-credit-card', command: () => {this.$router.push('mqttws'); }},
                        // {label: 'Mqtt', title: 'mqtt', icon: 'pi pi-fw pi-credit-card', command: () => {this.$router.push('mqtt'); }},
                                                
                    ]
                },
                {
                    label: process.env.VUE_APP_MENU_PERIODICALCHECK,
                    icon: 'pi pi-fw pi-list',
                    items: 
                    [
                        {label: process.env.VUE_APP_VAR_CHECKMGR, title: 'check', icon: 'pi pi-fw pi-wrench', command: () => {this.$router.push('check'); }},
                    ]
                },
    //             {
    //                 label: process.env.VUE_APP_MENU_MON,
    //                 icon: 'pi pi-fw pi-list',
    //                 items: 
    //                 [
    //                     {label: process.env.VUE_APP_VAR_MONORGMGR, title: 'mon_org', icon: 'pi pi-fw pi-chart-line', command: () => {this.$router.push('mon_org'); }},
    // //                    {separator:true},
    //                     {label: process.env.VUE_APP_VAR_MONORGSERCHMGR, title: 'mon_each_org', icon: 'pi pi-fw pi-chart-line', command: () => {this.$router.push('mon_each_org'); }},
    //                 ]
    //             },
            ],
        }
    },
    mounted() {
        // console.log(this.$router.getRoutes());
        // this.menuEnabled = true;
        // this.userMenuItems = this.AdminMenuItems;
    },
    computed: {
        getlogin() {
            this.$store.commit('setId', localStorage.getItem('Id'));
            this.$store.commit('setRole', localStorage.getItem('role'));
            this.tokenSize = this.$cookies.get('accessToken');
            if(!this.tokenSize)
            {
                this.doLogout();
                return null;
            }
            return this.$store.state.Id;                
        },
        getCurrentPage(){
            this.$store.state.currentPage = this.$cookies.get('currentPage');
            return this.$store.state.currentPage;
        },
    },
    methods: { 
        gotohome(){
            this.$router.push('home')
        },
        async doSend(type, hconfig, api, params){
            return await new Promise((resolve, reject) => {
                switch(type)
                {
                    case 'get':
                        this.$axios
                            .get(api, {
                                headers: hconfig,
                            })
                            .then((res) => {
                                resolve(res);
                            }).catch(err=>{
                                console.log(`${api} error`);
                                resolve(null);
                            });
                        break;
                    case 'post':
                        let formData = new FormData();
                        formData.append('PARAMS', JSON.stringify(params));
                        this.$axios
                            .post(api, formData, {
                                headers: hconfig,
                            })
                            .then((res) => {
                                resolve(res);
                            }).catch(err=>{
                                console.log(`${api} error`);
                                resolve(null);
                            });
                        break;
                }
            })
        },
        toggle(event) { 
            this.menuVisible = !this.menuVisible;           
//            this.$refs.menu.toggle(event);
        },
        selectedMenuItem(event){
            this.menuVisible = false;
        },
        recvMenuItem(event){
          this.menuVisible = !this.menuVisible;
          if(event !== '') this.$router.push(event.title)
        },        
        doLogout(){
            this.$store.dispatch('logOut');
            this.profileModal = false;
            // window.location.reload();
            this.$router.push('login');
        },
        doProfile() {
            this.profileModal = !this.profileModal;
        },
        async doChangePasswd(event, mod){
            let params = {};
            params.user_id = this.$store.state.Id;
            switch(mod){
                case 'modal':
                    this.passwd.user_current = '';
                    this.passwd.user_new = '';
                    this.passwd.user_comp = '';
                    this.changePasswdFlag = true;
                    return;
                case 'save':
                    if(this.passwd.user_current === '' || this.passwd.user_new === '') {
                        this.$toast.add({ severity: 'error', summary: '비밀번호 오류', detail: '비밀번호는 필수입니다.', life: 3000 });
                        return;
                    }
                    if(this.passwd.user_new !==  this.passwd.user_comp) {
                        this.$toast.add({ severity: 'error', summary: '비밀번호 오류', detail: '변경 비밀번호가 다릅니다.', life: 3000 });
                        return;
                    }
                    params.user_passwd = this.passwd.user_new;
                    params.current_passwd = this.passwd.user_current;
                    break;
                case 'init':
                    if(this.passwd.user_current === '') {
                        this.$toast.add({ severity: 'error', summary: '비밀번호 오류', detail: '현재 비밀번호는 필수입니다.', life: 3000 });
                        return;
                    }
                    params.user_passwd = process.env.VUE_APP_DEAULT_PASSWD;
                    params.current_passwd = this.passwd.user_current;
                    break;
            }
            this.doSend('post', this.httpConfig.headers, process.env.VUE_APP_API_USR_PWCHANGE, params).then((res) =>{
                if(res.data === 0){
                    this.$toast.add({ severity: 'info', summary: '변경 완료', detail: '비밀번호가 변경되었습니다. 다시 LOGIN해 주시기 바랍니다', life: 3000 });
                    this.doLogout();
                }
                else{
                    this.$toast.add({ severity: 'error', summary: '변경 실패', detail: '비밀번호 변경에 실패하였습니다. 다시 Confirm해 주시기 바랍니다', life: 3000 });
                }
                this.changePasswdFlag = false;
            }).catch(err => console.log('change assword data get error'))
        }
    }
}

</script>

@import "./assets/scss/app.scss";

<style lang="scss" scoped>
@media screen and (max-width: 60rem) {
    #med {
        display: none;
    }
}

.main {
    text-align: left;
    margin-left: 0px;
    padding: 0px;
}

.menubutton {
    width: 170px;
    //padding-top: 30px;
    //padding-bottom: 30px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    // margin-left: 30px;
    // margin-bottom: 30px;
    cursor: pointer;
    display: inline-block;
}

.button-1 {
    background-color: transparent;
    border: 1px solid #00d7c3;
    border-radius: 50px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    color: #d76100;
}

.button-1:hover {
    box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
    //  border: 1px solid #ec1580;
    font-size: 0.5rem;
}

.button-2 {
    color: #fff;
    border: 1px solid #c266d3;
    background-image: -webkit-linear-gradient(30deg, #c266d3 50%, transparent 50%);
    background-image: linear-gradient(30deg, #c266d3 50%, transparent 50%);
    // background-size: 500px;
    // background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button-2:hover {
    background-position: 100%;
    color: #c266d3;
}
</style>