import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    /**
     * 메인
     */
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
        meta: { title: 'Home', role: '', status: null, authRequired: false, icon: null},
        props: true,
    },
    {
        path: "",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
        meta: { title: 'Home', role: '', status: null, authRequired: false, icon: null},
        props: true,
    },
    /**
     * 권한
     */
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/loginView.vue"),
        meta: { title: 'LOGIN', role: '', status: null, authRequired: false, icon: null},
        props: true,
    },
    {
        path: "/org",
        name: "org",
        component: () => import(/* webpackChunkName: "org" */ "../views/orgManage.vue"),
        meta: { title: ' Organization', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-building' },
        props: true,
    },
    {
        path: "/device",
        name: "device",
        component: () => import(/* webpackChunkName: "device" */ "../views/deviceManage.vue"),
        meta: { title: 'Device Connection', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-tablet' },
        props: true,
    },
    // {
    //     path: "/gw",
    //     name: "gw",
    //     component: () => import(/* webpackChunkName: "gw" */ "../views/gwSerialManage.vue"),
    //     meta: { title: 'Gateway', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-tablet' },
    //     props: true,
    // },
    {
        path: "/watch",
        name: "watch",
        component: () => import(/* webpackChunkName: "watch" */ "../views/watchSerialManage.vue"),
        meta: { title: 'Watch', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-tablet' },
        props: true,
    },
    {
        path: "/dns",
        name: "dns",
        component: () => import(/* webpackChunkName: "dns" */ "../views/dnsManage.vue"),
        meta: { title: 'Domain', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-cog' },
        props: true,
    },
    {
        path: "/pet",
        name: "pet",
        component: () => import(/* webpackChunkName: "pet" */ "../views/petManage.vue"),
        meta: { title: 'PET', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-heart-fill' },
        props: true,
    },
    {
        path: "/petstd",
        name: "petstd",
        component: () => import(/* webpackChunkName: "petstd" */ "../views/petstdManage.vue"),
        meta: { title: 'PETSTD', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-heart-fill' },
        props: true,
    },
    {
        path: "/service",
        name: "service",
        component: () => import(/* webpackChunkName: "service" */ "../views/serviceManage.vue"),
        meta: { title: 'Service', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-heart-fill' },
        props: true,
    },
    {
        path: "/grade",
        name: "grade",
        component: () => import(/* webpackChunkName: "grade" */ "../views/usergradeManage.vue"),
        meta: { title: 'User grade', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-heart-fill' },
        props: true,
    },
    {
        path: "/agreed",
        name: "agreed",
        component: () => import(/* webpackChunkName: "agreed" */ "../views/useragreedManage.vue"),
        meta: { title: 'User agreement', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-heart-fill' },
        props: true,
    },
    {
        path: "/firm",
        name: "firm",
        component: () => import(/* webpackChunkName: "firm" */ "../views/firmManage.vue"),
        meta: { title: 'Firmware', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-info-circle' },
        props: true,
    },
    {
        path: "/qna",
        name: "qna",
        component: () => import(/* webpackChunkName: "qna" */ "../views/qnaManage.vue"),
        meta: { title: 'Q/A', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-chart-line' },
        props: true,
    },
    {
        path: "/check",
        name: "check",
        component: () => import(/* webpackChunkName: "check" */ "../views/checkManage.vue"),
        meta: { title: 'System check', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-wrench' },
        props: true,
    },
    // {
    //     path: "/mon_org",
    //     name: "mon_org",
    //     component: () => import(/* webpackChunkName: "mon" */ "../views/monitoring/monitoring_org.vue"),
    //     meta: { title: '모니터링', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-chart-line' },
    //     props: true,
    // },
    {
        path: "/com",
        name: "com",
        component: () => import(/* webpackChunkName: "com" */ "../views/common.vue"),
        meta: { title: 'Common code', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-cog' },
        props: true,
    },
    {
        path: "/rgn",
        name: "rgn",
        component: () => import(/* webpackChunkName: "rgn" */ "../views/resionManage.vue"),
        meta: { title: 'Region', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-cog' },
        props: true,
    },
    {
        path: "/log",
        name: "log",
        component: () => import(/* webpackChunkName: "log" */ "../views/loghistManage.vue"),
        meta: { title: 'LOGIN', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-cog' },
        props: true,
    },    
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    //     meta: { title: 'About', role: '', status: null, authRequired: false, icon: 'pi pi-fw pi-cog' },
    //     props: true,
    // },
    {
        path: '/:pathMatch(.*)*',
//        redirect: '/',
        component: () => import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    },
  
];

function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  }

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = getCookie("accessToken");
    if (to.name !== 'login' && !isAuthenticated) {
        next("login");
    }
    else if (to.name === 'login' && isAuthenticated)
    {
        next('home');
    }
    else {
        next();
    }
});

export default router;
