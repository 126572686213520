<template>
    <Sidebar v-if="menuVisible" v-model:visible="this.visible" @update:visible="dialogClose"
        style="width:20.5rem; background-color:rgb(255,255,255);">
        <template #header>
            <div class="flex" style="position:sticky; text-align:center; width:15rem">
                <div style="font-size: 1rem;font-weight: bold;">(C) CARETAIL KOREA</div>
            </div>
        </template>

        <Card style="width: 18rem; height: 9rem;">
            <template #header>
                <div style="text-align: center; ">
                    <img alt="user header" src="../images/peachbite.png" style="width:4rem;height:4rem" />
                </div>
            </template>
            <template #title>
                <!-- <div style="text-align: center;">(C) CARETAIL KOREA</div> -->
            </template>
            <template #subtitle>
                <div style="text-align: center; font-size: 1rem;">
                    Copyright © CARETAIL KOREA CO., LTD. All Rights Reserved.
                    <!-- <br>
                    <Button icon="pi pi-check" style="position:inline-block; margin-top:5px;height: 1.5rem; font-size: 1rem;"
                        label="Profile" /> -->
                </div>
            </template>
            <template #content>                
            </template>
            <template #footer>
                <div style="margin: 0 auto; text-align: center;">
                    <!-- <Button icon="pi pi-times" label="Setup" severity="secondary" class="justify-content-center"
                        style="margin-left: 0.5em; height: 10px; font-size: 5px;" /> -->
                </div>
            </template>
        </Card>
        <div style="margin-top:1.5rem;">
            <span v-for="(item, i) in this.userMenuItems" :key="i">
                <Button @click.prevent="doMenuSelect(i)" class="p-button-error"
                    style="margin-top:2px; font-size:1rem; width:18rem; font-weight:bold; color:white; background-color:dodgerblue">
                    <div>
                        <i :class=item.icon style="float:left;padding-left: 10px;padding-top: 4px;"></i>
                        <span style="padding-left: 10px;">{{ item.label }}</span>
                    </div>
                    <div style="width:16rem; position:absolute;">
                        <i v-if="upClicked[i]" class="pi pi-angle-down" style="float:right"></i>
                        <i v-else class="pi pi-angle-right" style="float:right"></i>
                    </div>
                </Button>
                <span v-for="(subitem, j) in item.items" :key="j" style="margin-left: 0rem;">
                    <Button v-if="upClicked[i]" icon="subitem.icon" class="menubutton button-1" @click.prevent="doReturn(i, j)"
                        style=" margin-top:1px;font-size:0.8rem; background-color:rgb(255,255,255); width:15rem; font-weight:bold; color:blue; ">
                        <!-- <img alt="user header" src="../images/peachbite.png" style="width:1.5rem; height: 1.5rem;"/> -->
                        <i :class=subitem.icon style="float:left;padding-left: 10px;"></i>
                        <span style="padding-left: 10px;">{{ subitem.label }}</span>
                    </Button>
                </span>
            </span>
        </div>
    </Sidebar>
</template>

<script>
import Sidebar from 'primevue/sidebar';

export default {
    components: { Sidebar, },
    props: ['menuVisible', 'userMenuItems', 'menuTitle'],
    emits: ['selected_menu'],
    data() {
        return {
            visible: true,
            upClicked: [],
        }
    },
    mounted(){
        process.env
    },
    unmounted() {
    },
    methods: {
        doMenuSelect(idx) {
            this.upClicked[idx] = !this.upClicked[idx];
        },
        doReturn(uidx, idx) {
            this.upClicked = [];
            this.$emit('selected_menu', this.userMenuItems[uidx].items[idx]);

        },
        dialogClose() {
            this.upClicked = [];
            this.visible = !this.visible;
            this.$emit('selected_menu', '');
        },
    }
}

</script>

<style>
.menubutton {
    width: 170px;
    /* height: 20px; */
    /* padding-top: 30px;
    padding-bottom: 30px; */
    text-align: left;
    /* color: #000; */
    text-transform: uppercase;
    /* font-weight: 600; */
    margin-left: 3rem;
    margin-bottom: 2px;
    cursor: pointer;
    display: inline-block;
}

.button-1 {
    background-color: transparent;
    border: 3px solid #00d7c3;
    border-radius: 20px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    color: #00d7c3;
}

.button-1:hover {
    box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
    border: 3px solid #00d7c3;
}

.button-2 {
    color: #fff;
    border: 3px solid #c266d3;
    background-image: -webkit-linear-gradient(30deg, #c266d3 50%, transparent 50%);
    background-image: linear-gradient(30deg, #c266d3 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button-2:hover {
    background-position: 100%;
    color: #c266d3;
}
</style>